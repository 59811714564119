@font-face {
  font-family: 'Figtree-Black';
  src: local('Figtree-Black'), url(../fonts/Figtree-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Figtree-Bold';
  src: local('Figtree-Bold'), url(../fonts/Figtree-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Figtree-Light';
  src: local('Figtree-Light'), url(../fonts/Figtree-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Figtree-Medium';
  src: local('Figtree-Medium'), url(../fonts/Figtree-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Figtree-Regular';
  src: local('Figtree-Regular'), url(../fonts/Figtree-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Figtree-SemiBold';
  src: local('Figtree-SemiBold'), url(../fonts/Figtree-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto-Sans';
  src: local('Noto-Sans'), url(../fonts/Noto-Sans.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto-Sans-Bold';
  src: local('Noto-Sans-Bold'), url(../fonts/Noto-Sans-Bold.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Figtree-Regular', 'Helvetica Neue', sans-serif;
}

.intercom-lightweight-app-launcher {
  bottom: 45px !important;
}
div.intercom-namespace div.intercom-app div.intercom-messenger-frame {
  bottom: 104px !important;
}
div.intercom-namespace div.intercom-app div.intercom-dfosxs {
  bottom: 45px !important;
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #fafafa;
}

@media (max-width: 600px) {
  html,
  body,
  div#root {
    overflow-x: clip;
  }
}

#__react-alert__ div {
  z-index: 100000 !important;
}
